// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'select2'
import 'select2/dist/css/select2.css'

import './stylesheets/application'

import MCDatepicker from 'mc-datepicker/dist/mc-calendar.min.js';
import './stylesheets/mc-calendar/main.scss';

import tippy from 'tippy.js';
import { delegate } from 'tippy.js';
import 'tippy.js/dist/tippy.css';

Rails.start()
Turbolinks.start()
// ActiveStorage.start()

global.$ = require('jquery');
global.jQuery = $;
window.jQuery = $;
require("jquery-ui-dist/jquery-ui");

// if (!Turbolinks) {
// location.reload();
// }

// Turbolinks.dispatch("turbolinks:load");

// window.onerror = function (err, file, line) {
//     console.log('The following error occurred: ' + 
//     err + '\nIn file: ' + file + '\nOn line: ' + line);
//     return true;
// }

document.addEventListener("turbolinks:load", function(e) {
  // console.log('event', e);
  $(document).on('change', '.service_option_select', function(){
    // console.log('el name', $(this).attr('name'));
    $.get(getUrl('/select/options'), function(){
      $('#service_name').select2();
    })
  })
  
  $('#service_name').select2();

  $(document).on('click', '#add_service', function(){
    var service = $("#service_name").val();
    // console.log('service', service);
    if(service && service.length > 0){
      var token = document.getElementsByName(
        "csrf-token"
      )[0].content;
      $.ajaxSetup({
         headers:{
            'X-CSRF-Token': token
         }
      });
      $.post('/services/add',{service: service}, function(){
        $("#service_name").val('').trigger('change');
        $('#order_form_error').addClass('hidden');
        $('#order_form_error .form-alert-error strong').text('');
      }).fail(function(error) { console.log(error) });
    }
  })

  delegate( '#step1_container', {
    target: '[data-tippy-content]',
    theme: 'whp'
  });

  $(document).on('click','#prev-week', function(e){
    e.preventDefault();
    var week = $(this).data('week-count');
    var top = $('#calendar_container .book-modal').css('top');
    if(parseInt(week) < 0){
      return
    }
    $('#loading').removeClass("hidden");
    var parentId = $('#parent_id').val();
    var service = $('#slot_service_id').val();
    $.get('/slots/availability',{service: service, parent_id: parentId, week: week}, function(){
      $('#loading').addClass("hidden");
      $('#calendar_container .book-modal').css('top', top);
    })
  })

  $(document).on('click','#next-week', function(e){
    e.preventDefault();
    var top = $('#calendar_container .book-modal').css('top');
    $('#loading').removeClass("hidden");
    var week = $(this).data('week-count');
    var parentId = $('#parent_id').val();
    var service = $('#slot_service_id').val();
    $.get('/slots/availability',{service: service, parent_id: parentId, week: week}, function(){
      $('#loading').addClass("hidden");
      $('#calendar_container .book-modal').css('top', top);
    })
  })

  $(document).on('click', '.dob', function(){
    var $this = $(this);
    var myDatePicker = MCDatepicker.create({
      dateFormat: 'yyyy-mm-dd',
      maxDate: new Date(),
      selectedDate: new Date('1970','01','01')
    });
    myDatePicker.open();
    myDatePicker.onSelect(function(date, formatedDate) { 
      // console.log('date', date, formatedDate);
      $this.val(formatedDate);
    });
  })

  $(document).on('click', '.date', function(){
    var $this = $(this);
    console.log('$this', $this);
    var myDatePicker1 = MCDatepicker.create({
      dateFormat: 'yyyy-mm-dd',
      minDate: new Date()
    });
    myDatePicker1.open();
    myDatePicker1.onSelect(function(date, formatedDate) { 
      // console.log('date', date, formatedDate);
      $this.val(formatedDate);
    });
  })

  $('.shortcut').on('ajax:success', function(data) {
    $('#order_form_error').addClass('hidden');
    $('#order_form_error .form-alert-error strong').text('');
  });

  $(document).on('click', 'a.save-service', function(e){
    e.preventDefault();
    var parentId = $(this).data('parent-id');
    var valid = validate_required(parentId);
    var valid_file = validate_file_format(parentId);
    if(!valid){
      return false;
    }
    if(!valid_file){
      return false;
    }
    $('#'+parentId).removeClass('form-service-list-act');
    $('#order_form_error').addClass('hidden');
    $('#order_form_error .form-alert-error strong').text('');
  })

  $(document).on('click', '.btn-edit', function(e){
    e.preventDefault();
    var parentId = $(this).data('parent-id');
    $('#'+parentId).addClass('form-service-list-act');
  })

  $(document).on('click', '.btn-delete', function(e){
    var parentId = $(this).data('parent-id');
    $('#'+parentId).remove();
    var extra_request_elems = $('.'+parentId);
    extra_request_elems.each(function(i, elem){
      var service_class = $(elem).attr('class').split(' ');
      if(service_class.length > 1){
        $(elem).removeClass(parentId);
      } else {
        $(elem).remove();
      }
    })
    var modalityId = $(this).data('modality-id');
    if($('#modality_'+modalityId+' div.form-service-list ul li').length === 0){
      $('#modality_'+modalityId).addClass('hidden');
    }
    if($('#selected_services ul li').length === 0){
      $('#step1_submit').addClass('hidden');
      $('#selected_services').addClass('hidden');
    }
    if($('#additional_info').html().length === 0){
      $('#additional_info').parent().addClass('hidden')
    }
  })

  $(document).on('click', '.btn-calendar', function(e){
    e.preventDefault();
    $('#loading').removeClass("hidden");
    var service = $(this).data('service');
    var top = $(this).offset().top;
    if(service.length === 0){
      var parent = $(this).closest('div.form-service-list');
      console.log('parent', parent);
      $(parent).find('> ul li input[class=service_identifier]').each(function(i, elem){
        if(i > 0){
          service += ',';
        }
        service += $(elem).val();
      })
    }
    // console.log('service', service);
    var parentId = $(this).parent().attr('id');
    $.get('/slots/availability',{service: service, parent_id: parentId}, function(){
      $('#loading').addClass("hidden");
      $('#calendar_container .book-modal').css('top', top - 350);
    })
    // $('.calendar_container').removeClass('hidden');
  })

  $(document).on('click', '#step1', function(){
    var valid = validate_required('step1_container');
    var valid_file = validate_file_format('step1_container');
    if(!valid){
      $([document.documentElement, document.body]).animate({
          scrollTop: $(".info-error:visible:first").offset().top
      }, 2000);
      return false;
    }
    if(!valid_file){
      $([document.documentElement, document.body]).animate({
          scrollTop: $(".info-error:visible:first").offset().top
      }, 2000);
      return false;
    }
    $('li.form-service-list-act').removeClass('form-service-list-act');
    $('.step_container').addClass('hidden');
    $('#step2_container').removeClass('hidden');
    $('.step').removeClass('item-act');
    $('#step2_btn').addClass('item-act');
    $('#order_form_error').addClass('hidden');
    $('#order_form_error .form-alert-error strong').text('');
  })

  $(document).on('click','#step1_btn', function(){
    $('.step_container').addClass('hidden');
    $('#step1_container').removeClass('hidden');
    $('.step').removeClass('item-act');
    $('#step1_btn').addClass('item-act');
  })

  $(document).on('click','#step2_btn', function(){
    $('.step_container').addClass('hidden');
    $('#step2_container').removeClass('hidden');
    $('.step').removeClass('item-act');
    $('#step2_btn').addClass('item-act');
  })

  $(document).on('click','#step3_btn', function(e){
    try{
      if(!validate_step2()){
        $('.step_container').addClass('hidden');
        $('#step2_container').removeClass('hidden');
        $('.step').removeClass('item-act');
        $('#step2_btn').addClass('item-act');  
        e.preventDefault();
        return false;
      }
      if(!validate_services()){
        $([document.documentElement, document.body]).animate({
          scrollTop: $(".info-error:visible:first").offset().top
      }, 2000);
        return false;
      }
      // $('.step_container').addClass('hidden');
      // $('#step3_container').removeClass('hidden');
      // $('.step').removeClass('item-act');
      // $('#step3_btn').addClass('item-act');
      // $('form#orderForm').submit();
      var form = document.querySelector('form');
      // form.dispatchEvent(new Event('submit', {bubbles: true}));
      // var input = $("<input>").attr("type", "hidden").attr('name', 'preview').val(true).appendTo('#orderForm');
      // form.append('<input type="hidden" name="preview" value="true" />');
      
      Rails.fire(form, 'submit');  
    }
    catch(e){
      console.log('error', e);
    }
  })

  $(document).on('click', '#preview_btn', function(e){
    if(!validate_step2()){
      // console.log('step 2 not valid');
      e.preventDefault();
      return false;
    }
    if(!validate_services()){
      // console.log('step 1 not valid');
      e.preventDefault();
      return false;
    }
    $('.step_container').addClass('hidden');
    $('#step3_container').removeClass('hidden');
    $('.step').removeClass('item-act');
    $('#step3_btn').addClass('item-act');
  })

  function validate_step2(){
    var valid = validate_required_step2();
    // if(!valid){
    //   return false;
    // }
    
    if(!valid){
      $([document.documentElement, document.body]).animate({
          scrollTop: $(".info-error:visible:first").offset().top
      }, 2000);
      return false;
    }
    return true;
  }

  function validate_services(){
    if($('#selected_services ul li').length == 0){
      $('.step_container').addClass('hidden');
      $('#step1_container').removeClass('hidden');
      $('.step').removeClass('item-act');
      $('#step1_btn').addClass('item-act');
      $('#order_form_error').removeClass('hidden');
      $('#order_form_error .form-alert-error strong').text('Please add services')
      // alert('Please add services');
      return false;
    }
    else if(!validate_required('step1_container') || !validate_file_format('step1_container')){
      $('.step_container').addClass('hidden');
      $('#step1_container').removeClass('hidden');
      $('.step').removeClass('item-act');
      $('#step1_btn').addClass('item-act');
      $('#order_form_error').removeClass('hidden');
      $('#order_form_error .form-alert-error strong').text('please save added services')
      // alert('please fix validation errors');
      return false; 
    }
    else {
      $('#order_form_error').addClass('hidden');
      $('#order_form_error .form-alert-error strong').text('');
      return true;
    }
  }

  $(document).on('click', '#step2_back', function(){
    $('.step_container').addClass('hidden');
    $('#step1_container').removeClass('hidden');
    $('.step').removeClass('item-act');
    $('#step1_btn').addClass('item-act');
  })

  $(document).on('click', '#step3_back', function(){
    $('.step_container').addClass('hidden');
    $('#step2_container').removeClass('hidden');
    $('.step').removeClass('item-act');
    $('#step2_btn').addClass('item-act');
  })

  $(document).on('click', '#privacy-policy', function(){
      if($(this).prop('checked') == true){
        $('#request_submit').prop('disabled', false);
        $('#request_submit').removeClass('btn-back').addClass('btn');
      } else{
        $('#request_submit').prop('disabled', true);
        $('#request_submit').removeClass('btn').addClass('btn-back');
      }
   });

  $('form').on('submit',function(){
    // console.log('form submit');
    $('#loading').removeClass("hidden");
    $('#request_error').html('');
  })

  $(document).on('ajax:send', function(event) {
    $('#loading').removeClass('hidden');
  });

  $(document).on('ajax:complete', function(event, jqXHR) {
    $('#loading').addClass('hidden');
  });

  $(document).on('ajax:error', function(event, jqXHR) {
    $('#loading').addClass('hidden');
  });

  $('form input').on('keypress', function(e) {
      return e.which !== 13;
  });

  $(document).on('change','[required]', function(){
    $(this).next().html('');
  })

  $(document).on('click','input[type=radio]', function(){
    if($(this).val()){
      $(this).parents('.radio-item').find('.info-error').html('');
    }
  })

  $(document).on('click', '#patient_id_check', function(){
    if($(this).is(':checked')){
      $('#patient_id_container').removeClass('hidden');
      $('#patient_details_container').addClass('hidden');
      $('#patient_details_container .info-error').html('');
      $('#patient_details_container input').val('');
    } else{
      $('#patient_details_container').removeClass('hidden');
      $('#patient_id_container').addClass('hidden');
      $('#patient_id_container .info-error').html('');
      $('#patient_id_container input').val('');
    }
  })

  $(document).on('click', 'input[name=patient_is_referrer]',function(){
    var value =  $(this).val();
    if(value == 'no'){
      $('#guest-additional-info').removeClass('hidden');
    } else {
      $('#guest-additional-info').addClass('hidden');
      $('#guest-additional-info .info-error').html('');
    }
  })

  $(document).on('click', 'ul.book-calendar-item-day > li', function(e){
    e.preventDefault();
    if($(this).hasClass('hour-select')){
      $("ul.book-calendar-item-day > li").removeClass('hour-select').removeClass('child_disabled');
      $(this).removeClass('hour-select');  
    } else {
      var slot = $(this).data('slot');
      $("ul.book-calendar-item-day > li").removeClass('hour-select').removeClass('child_disabled');
      $("ul.book-calendar-item-day > li > ul").removeAttr('style');
      $("li[data-slot="+slot+"]").addClass('hour-select').addClass('child_disabled');
      $("li[data-slot="+slot+"] > ul").css('height', $(this).find('ul').css('height'));
      $(this).removeClass('child_disabled');
    }
  })

  $(document).on('click', 'ul.book-calendar-item-day > li.hour-select > ul li', function(e){
    e.preventDefault();
    e.stopPropagation();
    var date = $(this).parents('.book-calendar-item').data('date');
    var scheduled = $(this).find('>a').hasClass('item-bookability');
    var parentId = $('#parent_id').val();
    var time = $(this).find('> a').text();
    if($('#'+parentId+' span.info-time').length > 0){
      $('#'+parentId+ ' span.info-time').remove();
    }
    $('#'+parentId).append(`<span class='info-time'>${date} - ${time}<span class='info-time-about'>(${scheduled == true ? 'booking time' : 'preferred time request'})</span></span>`);
    $('#'+parentId+' .date').val(date);
    $('#'+parentId+' .time').val(time);
    $('#'+parentId+' .scheduled').val(scheduled);
    $('#'+parentId+' .resource_type').val($(this).data('resource-type'));
    $('#'+parentId+' .resource_name').val($(this).data('resource-name'));
    $('#'+parentId+' .resource_id').val($(this).data('resource-id'));
    $('#calendar_container').addClass('hidden');  
    return;
  })

  $(document).on('click', '.book-modal a.btn-close-modal', function(e){
    e.preventDefault();
    $('#calendar_container').addClass('hidden');
  })

  $(document).on('click', '#clear_selection', function(e){
    e.preventDefault();
    $('#centre').val('');
    $('#category').val('');
    $('#subcategory').val('');
    $('#service_name').val('').trigger('change');
  })
  
});

function validate_required(selector){
  var valid = true;
  document.getElementById(selector).querySelectorAll("[required]").forEach(function(i) {
    // if (!valid) return false;
    if (!i.value) valid = false;
    // console.log(i, i.value);
    if (i.type === "radio") {
      var radioValueCheck = false;
      document.getElementById(selector).querySelectorAll(`[name="${i.name}"]`).forEach(function(r) {
        if (r.checked) {
          radioValueCheck = true;
          return;
        }
      })
      if(!radioValueCheck){
        valid = radioValueCheck;
        if($(i).parents('.radio-item').find('.info-error').length > 0){
          $(i).parents('.radio-item').find('.info-error').html('is Required');
        } else {
          $(i).parents('.radio-item').append("<span class='info-error'>is Required</span>")
        }  
      }
    }

    if(!i.value){
      // i.reportValidity();
      // return valid;
      if($(i).next().hasClass('info-error')){
        $(i).next().html('is Required');
      } else {
        $(i).after("<span class='info-error'>is Required</span>")
      }
    }
    // console.log('i, valid', i, valid);
  })
  return valid;
}

function is_phone_number(val){
    return /^([0-9\s]+)$/.test(val)
  }

function validate_required_step2(){
  var valid = true;
  var patient_is_referrer = $('input[name=patient_is_referrer]:checked');
  if(patient_is_referrer.length > 0){
    // console.log(patient_is_referrer.val());
    if(patient_is_referrer.val() == 'no'){
      var guest_info_valid = validate_required('guest-additional-info');
      if(!guest_info_valid){
        valid = guest_info_valid;
      } else {
        var email_field = document.getElementById("referrer_details_email");
        if(!email_field.validity.valid){
          // console.log('validationMessage', email_field.validationMessage);
          if($("#referrer_details_email").next().hasClass('info-error')){
            $("#referrer_details_email").next().html(email_field.validationMessage || 'Invalid value');
          } else {
            $("#referrer_details_email").after("<span class='info-error'>"+email_field.validationMessage || 'Invalid value'+"</span>")
          }
          return false;
        }
      }
    }
  }
  var patient_id_checked = $('#patient_id_check').is(':checked');
  var patient_info_valid;
  if(patient_id_checked){
    patient_info_valid = validate_required('patient_id_container');
  } else {
    patient_info_valid = validate_required('patient_details_container');
    var email_field = document.getElementById("patient_details_email");
    if(!email_field.validity.valid){
      if($("#patient_details_email").next().hasClass('info-error')){
        $("#patient_details_email").next().html(email_field.validationMessage || 'Invalid value');
      } else {
        $("#patient_details_email").after("<span class='info-error'>"+email_field.validationMessage || 'Invalid value'+"</span>")
      }
      valid = false;
    }
    var phone_number_field = document.getElementById("patient_details_phone_number");
    // console.log("phone number", phone_number_field.value, is_phone_number(phone_number_field.value), phone_number_field.value.charAt(0), phone_number_field.value.length > 21);
    if(phone_number_field.value && (phone_number_field.value.charAt(0) !== '0' || phone_number_field.value.length > 21 || !is_phone_number(phone_number_field.value))){
      var error = "Phone number should start with 0 and can be max 21 characters long, should contain only digits and spaces";
      if($("#patient_details_phone_number").next().hasClass('info-error')){
        $("#patient_details_phone_number").next().html(error);
      } else {
        $("#patient_details_phone_number").after("<span class='info-error'>"+error+"</span>")
      }
      valid = false;
    }
  }
  if(!patient_info_valid){
    valid = patient_info_valid;
    // console.log('i, valid', i, valid);
  }
  return valid; 
}

function validate_file_format(selector){
  var valid = true;
  var supportedFormats = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  document.getElementById(selector).querySelectorAll("input[type='file']").forEach(function(elem){
    if(elem.files.length > 0 && (supportedFormats.indexOf(elem.files[0].type) === -1)){
      if($(elem).next().hasClass('info-error')){
        $(elem).next().html('File format not supported');
      } else {
        $(elem).after("<span class='info-error'>File format not supported</span>")
      }
      valid = false;
      return false;
    }
  })
  return valid;
}
function getUrl(url){
  if(!url){
    var url = '/search';
  }
  var centre = $('#centre').val();
  var category = $('#category').val();
  var subcategory = $('#subcategory').val();
  var query = '';
  if(centre !== undefined){
    query += 'centre='+encodeURIComponent(centre)+'&';
  }
  if(category !== undefined){
    query += 'category='+encodeURIComponent(category)+'&';
  }
  if(subcategory !== undefined){
    query += 'subcategory='+encodeURIComponent(subcategory);
  }
  if(query !== ''){
    url += '?';
    url += query;
  }
  return url
}
